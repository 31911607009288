<template>
  <div class="main-content">
    <b-card>
      <good-data-table :dateFilter="true" ref="deposits" :button-label="$t('Add Deposit')"
              @add-new-info="Add_Deposit" :columns="columns" :no-serial="true" :total-column="'response.responseData.total'"
        :data-column="'response.responseData.data'" :api-endpoint="'/account/deposits'" />
    </b-card>
    <b-modal hide-footer no-close-on-backdrop no-close-on-esc v-model="addEditFormModal"
      :title="isEmpty(deposit.id) ? 'Add Deposit' : 'Edit Deposit'">
      <validation-observer ref="DepositForm">
        <b-form @submit.prevent="Submit_Deposit">
          <b-row>
            <!-- date  -->
            <b-col sm="12">
              <validation-provider name="date" :rules="{ required: true }" v-slot="validationContext">
                <b-form-group :label="$t('Date')">
                  <b-form-input :state="getValidationState(validationContext)" aria-describedby="date-feedback"
                    type="date" v-model="deposit.date"></b-form-input>
                  <b-form-invalid-feedback id="OrderTax-feedback">{{ validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Account -->
            <b-col sm="12">
              <validation-provider name="Account">
                <b-form-group slot-scope="{ valid, errors }" :label="$t('Account')">
                  <v-select :class="{ 'is-invalid': !!errors.length }"
                    :state="errors[0] ? false : (valid ? true : null)" v-model="deposit.account_id"
                    :reduce="label => label.value" :placeholder="$t('Choose Account')"
                    :options="accounts.map(accounts => ({ label: accounts.account_name, value: accounts.id }))" />
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Deposit_Category  -->
            <b-col sm="12">
              <validation-provider name="category" :rules="{ required: true }">
                <b-form-group slot-scope="{ valid, errors }" :label="$t('Deposit Category')">
                  <v-select :class="{ 'is-invalid': !!errors.length }"
                    :state="errors[0] ? false : (valid ? true : null)" v-model="deposit.category_id"
                    :reduce="label => label.value" :placeholder="$t('Choose Category')" :options="deposits_categories.map(deposit_category =>
                      ({ label: deposit_category.title, value: deposit_category.id }))" />
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Amount  -->
            <b-col sm="12">
              <validation-provider name="Amount" :rules="{ required: true, regex: /^\d*\.?\d*$/ }"
                v-slot="validationContext">
                <b-form-group :label="$t('Amount')">
                  <b-form-input :state="getValidationState(validationContext)" aria-describedby="Amount-feedback"
                    label="Amount" type="number" :placeholder="$t('Amount')" v-model="deposit.amount"></b-form-input>
                  <b-form-invalid-feedback id="Amount-feedback">{{ validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Details -->
            <b-col sm="12">
              <validation-provider name="Details">
                <b-form-group slot-scope="{ valid, errors }" :label="$t('Details')">
                  <textarea :class="{ 'is-invalid': !!errors.length }"
                    :state="errors[0] ? false : (valid ? true : null)" v-model="deposit.description" rows="4"
                    class="form-control" :placeholder="$t('Add more details')"></textarea>
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="12">
              <b-form-group>
                <b-button variant="primary" type="submit" :disabled="SubmitProcessing"><i
                    class="i-Yes me-2 font-weight-bold"></i> {{ $t('submit') }}</b-button>
                <div v-once class="typo__p" v-if="SubmitProcessing">
                  <div class="spinner sm spinner-primary mt-3"></div>
                </div>
              </b-form-group>
            </b-col>

          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>

  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// import jsPDF from "jspdf";
// import "jspdf-autotable";
import { showDangerNotification, showSuccessNotification } from "@/@core/comp-functions/ui/app";
import axios from 'axios'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
const apiUrl = `${jwtDefaultConfig.apiEndpoint}/account`;
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
import { isEmpty } from "@/utilities";

export default {
  components: {
    GoodDataTable
  },
  data() {
    return {
      addEditFormModal: false,
      SubmitProcessing: false,      
      deposit: {}
    };
  },

  computed: {
    ...mapGetters("account", ["accounts", "deposits_categories"]),
    columns() {
      return [
        {
          label: this.$t("Date"),
          field: "date"
        },
        {
          label: this.$t("Reference"),
          field: "deposit_ref"
        },
        {
          label: this.$t("Amount"),
          field: "amount",
        },
        {
          label: this.$t("Category"),
          field: "category_name"
        },
        {
          label: this.$t("Account"),
          field: "account_name"
        },
        {
          label: this.$t("Details"),
          field: "description"
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
          type: 'dropdown',
          actions: [
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('edit'),
              text: 'Edit',
              action: props => {
                this.Edit_Deposit(props)
              },
            },
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('delete'),
              text: 'Delete',
              action: props => {
                this.$swal({
                  title: this.$t('You want to delete?'),
                  iconHtml: this.$helpers.swalIcon('alert.png'),
                  showCancelButton: true,
                  confirmButtonText: this.$t('Yes'),
                  cancelButtonText: this.$t('No'),
                  customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger ml-1',
                    icon: 'border-0',
                  },
                  buttonsStyling: false,
                }).then(result => {
                  if (result.value) {
                    this.Remove_Deposit(props)
                  }
                })
              },
            },
          ],
        },
      ];
    }
  },

  methods: {
    isEmpty,
    ...mapActions('account', ['getAccountBasic']),
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    //------------------------------- Remove deposit -------------------------\\

    Remove_Deposit(props) {
      axios
        .delete(apiUrl + "/deposits/" + props.id)
        .then(() => {
          this.$refs.deposits.loadItems();
          showSuccessNotification(this, "Deleted successfully");
        })
        .catch(() => {
          showDangerNotification(this, "Something went wrong")
        });
    },

    Add_Deposit() {
      this.deposit = {
        date: "",
        account_id: "",
        category_id: "",
        description: "",
        amount: "",
      };
      this.addEditFormModal = true;
    },
    Edit_Deposit(props) {
      this.deposit = {
        id: props.id,
        date: props.date,
        account_id: props.account_id,
        category_id: props.category_id,
        description: props.description,
        amount: props.amount,
      };
      this.addEditFormModal = true;
    },
    Submit_Deposit() {
      this.$refs.DepositForm.validate().then(success => {
        if (!success) {
          showDangerNotification(this, "Please fill all data correctly");
        } else {
          if(!isEmpty(this.deposit.id)){
            const id = this.deposit.id
            axios
              .put(`${apiUrl}/deposits/${id}`, {
                deposit: this.deposit
              })
              .then(response => {
                this.$refs.deposits.loadItems();
                showSuccessNotification(this, "Deposit update successfully");
                this.SubmitProcessing = false;
                this.addEditFormModal = false;
              })
              .catch(error => {
                showDangerNotification(this, "Failed to update");
                this.SubmitProcessing = false;
              });
          }else{
            axios
            .post(apiUrl+"/deposits", {
              deposit: this.deposit
            })
            .then(response => {
              this.$refs.deposits.loadItems();
              showSuccessNotification(this, "Deposit created successfully");
              this.SubmitProcessing = false;
              this.addEditFormModal = false;
            })
            .catch(error => {
              showDangerNotification(this, "Failed to save");
              this.SubmitProcessing = false;
            });
          }
        }
      });
    },
  },

  mounted: function () {
    this.getAccountBasic({
      only: ["accounts", "deposits_categories"]
    });
    this.$refs.deposits.loadItems();
  }
};
</script>